import { Observable } from 'rxjs';
import { createOperationObservable, ValidationRule } from 'state-domains/domain';

import { buildValidationRuleIdUrl } from '../../../network/urls';

export const saveConfigurationValidationRule = (
    payload: any,
    id: string,
): Observable<ValidationRule> =>
    createOperationObservable('edit', buildValidationRuleIdUrl, undefined, id, payload);
